import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import QueueIcon from '@material-ui/icons/FormatListNumbered';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { useObservable } from '../hooks/useObservable';
import { getUser, logout, login } from '../services/AuthService';
import { getQueue } from '../services/QueueService';
import RoundPicks from './RoundPicks';
import TeamQueue from './TeamQueue';
import TeamList from './TeamList';

function AppWrapper() {
    const user = useObservable(getUser());
    const queue = useObservable(getQueue(), []);
    const [page, setPage] = useState('draft');
    const [tab, setTab] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleTabChange = (_, value) => {
        setTab(value);
    };

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const navigate = route => () => {
        setPage(route);
        handleMenuToggle();
    };

    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h6"
                        color="inherit"
                        className="flex-grow"
                    >
                        Draft Center
                    </Typography>
                    {user ? (
                        <IconButton style={{display: 'none'}} onClick={handleMenuToggle}>
                            <MenuIcon htmlColor="white" />
                        </IconButton>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={login}
                        >
                            Login
                        </Button>
                    )}
                    <Badge
                        className="ml-4"
                        invisible={queue.length === 0}
                        badgeContent={queue.length}
                        color="secondary"
                    >
                        <QueueIcon onClick={() => setTab(2)} />
                    </Badge>
                </Toolbar>
            </AppBar>
            <Drawer anchor="right" open={menuOpen} onClose={handleMenuToggle}>
                <List style={{width: '250px'}}>
                    <ListItem button onClick={navigate('draft')}>
                        <ListItemIcon><QueueIcon/></ListItemIcon>
                        <ListItemText primary="Draft Center" />
                    </ListItem>
                    <ListItem button onClick={navigate('stable')}>
                        <ListItemIcon><PeopleIcon/></ListItemIcon>
                        <ListItemText primary="Stables" />
                    </ListItem>
                    <Divider/>
                    <ListItem button onClick={logout}>
                        <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                        <ListItemText primary="Logout"/>
                    </ListItem>
                </List>
            </Drawer>
            {user ? (
                page === 'draft' ? (
                    <React.Fragment>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                            >
                                <Tab label="Round Picks" />
                                <Tab label="Team List" />
                                <Tab label="My Queue" />
                            </Tabs>
                        </AppBar>
                        {tab === 0 ? (
                            <RoundPicks />
                        ) : tab === 1 ? (
                            <TeamList />
                        ) : (
                            <TeamQueue />
                        )}
                        <div
                            className={tab !== 1 ? 'hidden' : ''}
                            id="fabRoot"
                        />{' '}
                    </React.Fragment>
                ) : (
                    <div>Stables</div>
                )
            ) : (
                <div className="flex flex-col flex-grow justify-center items-center content-center">
                    <Button variant="contained" color="primary" onClick={login}>
                        Login
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
}

export default AppWrapper;
